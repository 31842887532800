<template>
    <el-main>
        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
            <el-form-item label="选择商品">
                <div class="dataBox">
                    <el-button type="text" @click="isChooseGoods = true">选择商品</el-button>
                    <el-table :data="ruleForm.goods" style="width: 100%"
                        :header-cell-style="{ 'background-color': '#F8F9FA' }" size="mini">
                        <template slot="empty">
                            <No />
                        </template>
                        <el-table-column label="商品" align="center">
                            <template v-slot="{ row }">
                                <div class="goods_info" v-if="row.goods_cover_picture">
                                    <el-image :src="row.goods_cover_picture" style="width:40px;height:40px"
                                        :preview-src-list="[row.goods_cover_picture]"></el-image>
                                    <div class="goods_name">{{ row.goods_name }}</div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="goods_price" label="价格" align="center"></el-table-column>
                        <el-table-column label="操作" align="center">
                            <template v-slot="{ row }">
                                <el-button v-if="row.goods_cover_picture" type="text"
                                    @click="deleteChooseGoods">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-form-item>
            <el-form-item label="批发阶梯价格">
                <table class="ladderTable">
                    <thead style="background-color:#F8F9FA" border="1px">
                        <th>阶梯购买</th>
                        <!--<th>批发价格(元)</th>
                        <th>批发库存</th>-->
                    </thead>
                    <tr>
                        <td>
                            <el-input type="number" size="small" v-model="ladderPrice[0]"
                                placeholder="请填写最低起批量"></el-input>
                            至
                            <el-input type="number" size="small" v-model="ladderPrice[1]"></el-input>
                            份(不包括)
                        </td>
                        <!--<td><el-input size="small"></el-input></td>
                        <td><el-input size="small"></el-input></td>-->
                    </tr>
                    <tr>
                        <td>
                            <el-input type="number" size="small" v-model="ladderPrice[1]" disabled
                                placeholder=""></el-input>
                            至
                            <el-input type="number" size="small" v-model="ladderPrice[2]"></el-input>
                            份(不包括)
                        </td>
                        <!--<td><el-input size="small"></el-input></td>
                        <td><el-input size="small"></el-input></td>-->
                    </tr>
                    <tr>
                        <td>
                            <el-input type="number" disabled size="small" v-model="ladderPrice[2]"></el-input>及以上(包括)
                        </td>
                        <!--<td><el-input size="small"></el-input></td>
                        <td><el-input size="small"></el-input></td>-->
                    </tr>
                </table>
            </el-form-item>
            <el-form-item label="是否支持混批">
                <div class="specBox">
                    <div class="isMixedbatchh">
                        <el-radio-group v-model="is_mixedbatchh">
                            <el-radio :label="0">不支持</el-radio>
                            <el-radio :label="1">支持</el-radio>
                        </el-radio-group>
                        <div class="tips">选择支持混批,则表示当用户购买多个规格时,全部规格的价格都按总数的阶梯价格计算,不支持混批,则按照每个规格数量对应的阶梯价格计算</div>
                    </div>
                    <!-- 渲染规格表格 -->
                    <table class="ladderTable">
                        <thead style="background-color:#F8F9FA" border="1px">
                            <th v-for="(item, index) in ruleForm.goods[0].spec" :key="index">{{ item.spec_name }}</th>
                            <th>阶梯购买</th>
                            <th>批发价格(元)</th>
                            <th>批发库存</th>
                        </thead>
                        <tbody v-for="(item, index) in ruleForm.goods[0].spec_sku" :key="index">
                            <tr>
                                <td rowspan="3">{{ item.spec_name_arr[0] }}</td>
                                <td rowspan="3">{{ item.spec_name_arr[1] }}</td>
                                <td>{{ ladderPrice[0] }} ~ {{ ladderPrice[1] }}个(不包括)</td>
                                <td><el-input type="number" size="small" style="width:70px"
                                        v-model="item.wholesalePrice[0]"></el-input></td>
                                <td rowspan="3"><el-input type="number" size="small" style="width:70px"
                                        v-model="item.wholesaleStock"></el-input></td>
                            </tr>
                            <tr>
                                <td>{{ ladderPrice[1] }} ~ {{ ladderPrice[2] }}个(不包括)</td>
                                <td><el-input type="number" size="small" style="width:70px"
                                        v-model="item.wholesalePrice[1]"></el-input></td>
                            </tr>
                            <tr>
                                <td>{{ ladderPrice[2] }}个以上(包括)</td>
                                <td><el-input type="number" size="small" style="width:70px"
                                        v-model="item.wholesalePrice[2]"></el-input></td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- 批量输入 -->
                    <div class="batchInput">
                        <div>批量输入:</div>
                        <el-input type="number" size="small" v-model="batchInput.price" placeholder="价格(元)"></el-input>
                        <el-input type="number" size="small" v-model="batchInput.stock" placeholder="库存"></el-input>
                        <el-button size="small" type="primary" @click="batchInputFn">确定</el-button>
                    </div>
                </div>
            </el-form-item>
        </el-form>

        <Preservation @preservation="onSubmit()"></Preservation>

        <!-- 选择商品弹窗 -->
        <el-dialog title="选择批发商品" :visible.sync="isChooseGoods" width="700px">
            <div>
                <div class="searchBox">
                    <el-input v-model="goodsForm.name" placeholder="请输入商品名称"></el-input>
                    <el-button type="primary" @click="getGoodsList(1)">搜索</el-button>
                </div>
                <el-table :data="goodsForm.list" style="width: 100%"
                    :header-cell-style="{ 'background-color': '#F8F9FA' }" size="mini">
                    <template slot="empty">
                        <No />
                    </template>
                    <el-table-column prop="goods_name" label="商品名称" align="center"></el-table-column>
                    <el-table-column label="商品图片" align="center">
                        <template v-slot="{ row }">
                            <el-image :src="row.goods_cover_picture" style="width:40px;height:40px"
                                :preview-src-list="[row.goods_cover_picture]"></el-image>
                        </template>
                    </el-table-column>
                    <el-table-column prop="goods_price" label="商品价格" align="center"></el-table-column>
                    <el-table-column label="商品库存" align="center">
                        <template v-slot="{ row }">
                            {{ row.goods_stock == -1 ? '无限库存' : row.goods_stock }}
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template v-slot="{ row }">
                            <el-button type="text" @click="searchGoodsDetail(row)">选择</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <Paging :total="goodsForm.total" :page="goodsForm.page" :pageNum="goodsForm.rows"
                    @updatePageNum="updateGoodsData">
                </Paging>
            </div>
        </el-dialog>
    </el-main>
</template>

<script>
import Paging from '@/components/paging';
import Preservation from '@/components/preservation';
const industryName = '/Gateway';//行业版本接口名称（用于更换重复接口）
export default {
    components: {
        Paging,
        Preservation
    },
    data () {
        return {
            edit_id: null,
            ruleForm: {
                goods: [
                    { goods_cover_picture: '', spec: [] }
                ]
            },
            isChooseGoods: false,
            goodsForm: {
                name: '',
                page: 1,
                rows: 10,
                total: 0,
                list: []
            },
            is_mixedbatchh: 0,
            //阶梯数组
            ladderPrice: [],
            batchInput: {
                price: '',
                stock: ''
            }
        }
    },
    created () {
        this.getGoodsList()
        if (this.$route.query.edit_id) {
            this.edit_id = this.$route.query.edit_id
            this.getEditInfo()
        }
    },
    methods: {
        getEditInfo () {
            this.$axios.post(this.$api.push.wholesale.infoWholesale, { id: this.edit_id })
                .then(res => {
                    if (res.code == 0) {
                        this.ladderPrice[0] = Number(res.result.ladder_price_info[0].start)
                        this.ladderPrice[1] = Number(res.result.ladder_price_info[1].start)
                        this.ladderPrice[2] = Number(res.result.ladder_price_info[2].start)
                        this.is_mixedbatchh = Number(res.result.is_mixedbatchh)
                        res.result.goods.spec_sku.map((item, index) => {
                            item.wholesaleStock = res.result.skulist[index].stock
                            item.wholesale_sku_id = res.result.skulist[index].id
                            item.spec_name_arr = item.title.split('+')
                            if (index === 0) {
                                item.wholesalePrice = [res.result.ladder_price_info[0].price, res.result.ladder_price_info[1].price, res.result.ladder_price_info[2].price]
                            } else {
                                let start = index * 3
                                item.wholesalePrice = [res.result.ladder_price_info[start].price, res.result.ladder_price_info[start + 1].price, res.result.ladder_price_info[start + 2].price]
                            }
                        })
                        this.$set(this.ruleForm.goods, 0, res.result.goods)
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
        },
        updateGoodsData (val, status) {
            if (status == 0) {
                this.goodsForm.rows = val;
            } else {
                this.goodsForm.page = val;
            }
            this.getGoodsList();
        },
        // 获取待选择的商品列表
        getGoodsList (style) {
            if (style) this.goodsForm.page = 1
            let obj = {
                page: this.goodsForm.page,
                rows: this.goodsForm.rows
            }
            if (this.goodsForm.name) obj.goods_name = this.goodsForm.name
            this.$axios.post(industryName + this.$api.goods.list, obj).then(res => {
                if (res.code == 0) {
                    this.goodsForm.list = res.result.list
                    this.goodsForm.total = res.result.total_number
                } else {
                    this.$message.warning(res.msg)
                }
            })
        },
        // 获取选中的商品详细信息
        searchGoodsDetail (data) {
            if (!data.is_spec) {
                this.$message.warning('只有具备两种规格的商品才能参与批发')
                return
            }
            this.$axios.post(industryName + this.$api.goods.goodsInfo, { id: data.id })
                .then(res => {
                    if (res.code == 0) {
                        if (res.result.spec.length != 2) {
                            this.$message.warning('只有具备两种规格的商品才能参与批发')
                        } else {
                            res.result.spec_sku.map(spec => {
                                spec.spec_name_arr = spec.title.split('+')
                                spec.wholesalePrice = ['', '', '']
                                spec.wholesaleStock = ''
                            })
                            this.$set(this.ruleForm.goods, 0, res.result)
                            this.isChooseGoods = false
                        }
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
        },
        deleteChooseGoods () {
            this.$set(this.ruleForm.goods, 0, { goods_cover_picture: '', spec: [] })
        },
        // 提交规格
        onSubmit () {
            if (!this.checkDataInfo()) return
            // 校验数据
            let obj = {
                goods_id: this.ruleForm.goods[0].id,
                is_mixedbatchh: this.is_mixedbatchh,
                ladder_price: [],
                spec: []
            }
            this.ruleForm.goods[0].spec_sku.map(spec => {
                let arr = [
                    { start: this.ladderPrice[0], end: this.ladderPrice[1], price: spec.wholesalePrice[0] },
                    { start: this.ladderPrice[1], end: this.ladderPrice[2], price: spec.wholesalePrice[1] },
                    { start: this.ladderPrice[2], end: 'max', price: spec.wholesalePrice[2] },
                ]
                obj.ladder_price = obj.ladder_price.concat(arr)
                let objSon = {
                    sku_id: spec.id,
                    stock: spec.wholesaleStock,
                    ladder_price: arr
                }
                if (this.edit_id) objSon.wholesale_sku_id = spec.wholesale_sku_id
                obj.spec.push(objSon)
            })
            if (this.edit_id) {
                obj.id = this.edit_id
            }
            let path = this.edit_id ? this.$api.push.wholesale.editWholesale : this.$api.push.wholesale.addWholesale
            this.$axios.post(path, obj).then(res => {
                if (res.code === 0) {
                    this.$message.success('添加成功')
                    this.$router.push('/extension/gatewayWholesale/list')
                } else {
                    this.$message.warning(res.msg)
                }
            })
        },
        // 批量输入价格和库存 -> 全覆盖
        batchInputFn () {
            if (!this.ruleForm.goods[0].id) {
                this.$message.warning('请选择需要批发的商品')
                return
            }
            this.ruleForm.goods[0].spec_sku.map(sku => {
                if (this.batchInput.price != '') sku.wholesalePrice = [this.batchInput.price, this.batchInput.price, this.batchInput.price]
                if (this.batchInput.stock != '') sku.wholesaleStock = this.batchInput.stock
            })
        },
        // 校验添加的数据
        checkDataInfo () {
            if (!this.ruleForm.goods[0].id) {
                this.$message.warning('请选择需要批发的商品')
                return false
            }
            let flag = true
            for (let i = 0; i < this.ladderPrice.length; i++) {
                if (this.ladderPrice[i] === '') {
                    this.$message.warning('请将阶梯价格设置完整')
                    flag = false
                    break
                }
                if (isNaN(Number(this.ladderPrice[i]))) {
                    this.$message.warning('请填写合规阶梯价格')
                    flag = false
                    break
                }
            }
            if (this.ladderPrice[0] > this.ladderPrice[1] || this.ladderPrice[0] > this.ladderPrice[2] || this.ladderPrice[1] > this.ladderPrice[2]) {
                this.$message.warning('阶梯范围设置错误')
                return false
            }
            if (!flag) return false
            let data = this.ruleForm.goods[0].spec_sku
            for (let i = 0; i < data.length; i++) {
                let flagSon = true
                for (let j = 0; j < data[i].wholesalePrice.length; j++) {
                    if (data[i].wholesalePrice[j] === '') {
                        this.$message.warning(`请填写${data[i].title}规格的批发价格`)
                        flagSon = false
                        flag = false
                        break
                    }
                    if (isNaN(Number(data[i].wholesalePrice[j]))) {
                        this.$message.warning(`请合规填写${data[i].title}规格的批发价格`)
                        flagSon = false
                        flag = false
                        break
                    }
                }
                if (!flagSon) break
                if (data[i].wholesaleStock === '') {
                    this.$message.warning(`请填写${data[i].title}规格的批发库存`)
                    flag = false
                    break
                }
                if (isNaN(Number(data[i].wholesaleStock))) {
                    this.$message.warning(`请合规填写${data[i].title}规格的批发库存`)
                    flag = false
                    break
                }
            }
            if (!flag) return false
            return true
        }
    },
}
</script>

<style lang="less" scoped>
.el-main {
    background-color: #fff;
    min-height: 100%;
    padding-bottom: 80px;

    .searchBox {
        display: flex;
        margin-bottom: 10px;
    }

    .goods_info {
        display: flex;
        align-items: center;

        .el-image {
            margin-right: 10px;
        }

        .goods_name {
            text-align: left;
            width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
        }
    }

    .ladderTable {
        width: 100%;
        border-collapse: collapse;
        max-width: 500px;

        &,
        thead,
        th,
        tr,
        td {
            border: 1px solid #EBEEF5;
            text-align: center;
        }

        thead {
            background-color: #F8F9FA;
        }

        tr {
            width: 100px;

            td:first-of-type {
                white-space: nowrap;
            }

            td:not(:first-of-type) {

                .el-input {
                    width: 100px !important;
                }
            }
        }

        td {

            .el-input {
                width: 200px;
                margin: 0 20px;
            }
        }
    }

    .specBox {
        .isMixedbatchh {
            display: flex;
            align-items: center;

            .el-radio-group {
                margin-right: 20px;
            }
        }

        .tips {
            color: #bbbbbb;
        }

        .batchInput {
            display: flex;
            align-items: center;
            margin-top: 10px;

            .el-input {
                width: 100px;
                margin-left: 10px;
            }

            .el-button {
                margin-left: 10px;
            }
        }
    }
}
</style>